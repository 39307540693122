import { isBrowser } from "./isBrowser";

const getEnv = () => {
  if (process.env.NODE_ENV === "test") {
    return process.env;
  }

  return isBrowser() ? (window as any).ENV : process.env;
};

export default getEnv;
